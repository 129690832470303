export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js")
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/admin": [7,[2,3]],
		"/(app)/admin/settings": [8,[2,3]],
		"/auth": [27],
		"/avareceive/[id]": [28],
		"/(app)/c/[id]": [9,[2]],
		"/error": [29],
		"/(app)/generation": [10,[2]],
		"/(app)/playground": [11,[2,4]],
		"/(app)/siamavatar": [12,[2]],
		"/s/[id]": [30],
		"/(app)/workspace": [13,[2,5]],
		"/(app)/workspace/documents": [14,[2,5]],
		"/(app)/workspace/functions": [15,[2,5]],
		"/(app)/workspace/functions/create": [16,[2,5]],
		"/(app)/workspace/functions/edit": [17,[2,5]],
		"/(app)/workspace/models": [18,[2,5]],
		"/(app)/workspace/models/create": [19,[2,5]],
		"/(app)/workspace/models/edit": [20,[2,5]],
		"/(app)/workspace/prompts": [21,[2,5]],
		"/(app)/workspace/prompts/create": [22,[2,5]],
		"/(app)/workspace/prompts/edit": [23,[2,5]],
		"/(app)/workspace/tools": [24,[2,5]],
		"/(app)/workspace/tools/create": [25,[2,5]],
		"/(app)/workspace/tools/edit": [26,[2,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";